import React, { useState } from "react";
import SlideToggle from "react-slide-toggle";
import { useSelector } from "react-redux";

import ALink from "./alink";
import { useNavigate } from "react-router-dom";

function MobileMenu() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  const Search = () => {
    navigate("/search/" + searchTerm + "/3cols/1");
  };

  const menu = useSelector((state) => state.menu.menu);

  function hideMobileMenu() {
    document.querySelector("body").classList.remove("mmenu-active");
  }

  function onSearchChange(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <div className="mobile-menu-container">
      <div className="mobile-menu-wrapper">
        <span className="mobile-menu-close" onClick={hideMobileMenu}>
          <i className="icon-close"></i>
        </span>

        <form
          action="#"
          method="get"
          onSubmit={Search}
          className="mobile-search"
        >
          <label htmlFor="mobile-search" className="sr-only">
            Search
          </label>
          <input
            type="text"
            className="form-control"
            value={searchTerm}
            onChange={onSearchChange}
            name="mobile-search"
            id="mobile-search"
            placeholder="Search product ..."
            required
          />
          <button className="btn btn-primary" type="submit">
            <i className="icon-search"></i>
          </button>
        </form>

        <nav className="mobile-nav">
          <ul className="mobile-menu">
            {menu.map((item, firstIndex) => {
              return (
                <SlideToggle key={firstIndex} collapsed={true}>
                  {({ onToggle, setCollapsibleElement, toggleState }) => (
                    <li
                      className={
                        toggleState.toLowerCase() === "expanded" ? "open" : ""
                      }
                    >
                      <ALink href={item.Url}>
                        {item.ItemTitle}
                        {item.MegaMenu && (
                          <span
                            className="mmenu-btn"
                            onClick={(e) => {
                              onToggle(e);
                              e.preventDefault();
                            }}
                          ></span>
                        )}
                      </ALink>
                      {item.MegaMenu && (
                        <ul ref={setCollapsibleElement}>
                          {item.Sub.map((sub, secondIndex) => {
                            return (
                              <SlideToggle key={secondIndex} collapsed={true}>
                                {({
                                  onToggle,
                                  setCollapsibleElement,
                                  toggleState,
                                }) => (
                                  <li
                                    className={
                                      toggleState.toLowerCase() === "expanded"
                                        ? "open"
                                        : ""
                                    }
                                  >
                                    <ALink href="/" className="sf-with-ul">
                                      {sub.ItemTitle}
                                      <span
                                        className="mmenu-btn"
                                        onClick={(e) => {
                                          onToggle(e);
                                          e.preventDefault();
                                        }}
                                      ></span>
                                    </ALink>
                                    <ul ref={setCollapsibleElement}>
                                      {sub.Sub.map((subsub, thirdIndex) => {
                                        return (
                                          <li key={thirdIndex}>
                                            <ALink
                                              href={
                                                subsub.CategoryId !== 0
                                                  ? process.env.PUBLIC_URL +
                                                    "/category/" +
                                                    subsub.ItemTitle.replace(
                                                      / /g,
                                                      "-"
                                                    ) +
                                                    "/" +
                                                    subsub.ItemId +
                                                    "/3cols/1"
                                                  : process.env.PUBLIC_URL +
                                                    "/brand/" +
                                                    subsub.ItemTitle.replace(
                                                      / /g,
                                                      "-"
                                                    ) +
                                                    "/" +
                                                    subsub.ItemId +
                                                    "/3cols/1"
                                              }
                                            >
                                              {subsub.ItemTitle}
                                            </ALink>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </li>
                                )}
                              </SlideToggle>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  )}
                </SlideToggle>
              );
            })}
          </ul>
        </nav>

        <div className="social-icons">
          <a
            href="https://www.facebook.com/SIS.BrandOfficial/"
            className="social-icon"
            title="Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-facebook-f"></i>
          </a>
          <a
            href="https://www.instagram.com/smallinsize.ae/"
            className="social-icon"
            title="Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-instagram"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MobileMenu);
