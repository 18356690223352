import Modal from "react-modal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Magnifier } from "react-image-magnifiers";

import OwlCarousel from "../owl-carousel";
import DetailOne from "../../partials/product/details/detail-one";

import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Global } from "../../../Global";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(51,51,51,0.6)",
    zIndex: "9000",
  },
};

Modal.setAppElement("body");

function QuickViewModal({ product, variation, show, onHide }) {
  const [carouselRef, setCarouselRef] = useState(null);

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedVariation, setSelectedVariation] = useState({
    color: "",
    hex: "",
    image: [],
    discount: 0,
    size: "",
    price: 0,
    productVariationId: 0,
    saleprice: 0,
    stock: 0,
  });
  const navigate=useNavigate()

  const events = {
    onTranslate: function (e) {
      let items = document.querySelectorAll(
        ".quickView-content .product-gallery-item"
      );
      document
        .querySelector(".quickView-content .product-gallery-item.active")
        .classList.remove("active");
      items[e.item.index].classList.add("active");
    },
  };

  function closeModal() {
    if (document.querySelector(".ReactModal__Content")) {
      document
        .querySelector(".ReactModal__Content")
        .classList.remove("ReactModal__Content--after-open");
    }

    if (document.querySelector(".ReactModal__Overlay")) {
      document.querySelector(".ReactModal__Overlay").style.opacity = "0";
    }

    setTimeout(() => {
      onHide();
    }, 250);
  }

  function changeBgImage(e, index, image) {
    document
      .querySelector(".quickView-content .product-gallery-item.active")
      .classList.remove("active");
    e.currentTarget.classList.add("active");
    carouselRef.current.goTo(index);
    setSelectedImage(image);
  }

  const changeColor = (value) => {
    product.variation.forEach((color) => {
      if (color.color === value) {
        color.size.forEach((size, index) => {
          if (index === 0) {
            setSelectedVariation({
              color: color.color,
              hex: color.hex,
              image: color.image,
              discount: size.discount,
              size: size.name,
              price: size.price,
              productVariationId: size.productVariationId,
              saleprice: size.saleprice,
              stock: size.stock,
            });
            setSelectedImage(color.image[0]);
          }
        });
      }
    });
  };

  const changeSize = (value) => {
    product.variation.forEach((color) => {
      if (color.color === selectedVariation.color) {
        color.size.forEach((size) => {
          if (size.name === value) {
            setSelectedVariation({
              color: color.color,
              image: color.image,
              hex: color.hex,
              discount: size.discount,
              size: size.name,
              price: size.price,
              productVariationId: size.productVariationId,
              saleprice: size.saleprice,
              stock: size.stock,
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    setSelectedVariation(variation);
    setSelectedImage(variation.image[0]);
  }, [variation]);

  return (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      className="container quickView-container"
      overlayClassName="d-flex align-items-center justify-content-center"
      shouldReturnFocusAfterClose={false}
      closeTimeoutMS={100}
      contentLabel="QuickView"
      style={customStyles}
      id="product-quickview"
    >
      <div className="modal-content">
        <div className="quickView-content horizontal skeleton-body">
          <div className="row skel-pro-single skel-quickview mb-0 loaded">
            <div className="col-lg-6 p-0 pr-lg-2 mb-2 mb-lg-0">
              <div className="skel-product-gallery"></div>

              <>
                <div className="product-lg mb-1 position-relative">
                  {product.new ? (
                    <span className="product-label label-new" onClick={()=>navigate(`/product/${product.id}`)}>New</span>
                  ) : (
                    ""
                  )}

                  {selectedVariation.saleprice !== 0 ? (
                    <span className="product-label label-sale" onClick={()=>navigate(`/product/${product.id}`)}>Sale</span>
                  ) : (
                    ""
                  )}

                  {/* {!selectedVariation.stock || selectedVariation.stock === 0 ? (
                    <span className="product-label label-out">
                      Out of Stock
                    </span>
                  ) : (
                    ""
                  )} */}
                  <OwlCarousel
                    adClass="product-gallery-carousel owl-full owl-nav-dark cols-1 cols-md-2 cols-lg-3"
                    onChangeRef={setCarouselRef}
                    events={events}
                    options={{ dots: false, nav: false }}
                  >
                    <Magnifier
                      imageSrc={Global.PHOTO_URL + selectedImage}
                      imageAlt="product"
                      dragToMove={false}
                      mouseActivation="hover"
                      cursorStyleActive="crosshair"
                      className="product-gallery-image"
                      style={{
                        paddingTop: `${100}%`,
                      }}
                    />
                  </OwlCarousel>
                </div>

                <div className="product-sm row px-2" id="owl-dots">
                  {selectedVariation.image.map((item, index) => (
                    <button
                      className={`product-gallery-item mb-0 ${
                        0 === index ? "active" : ""
                      }`}
                      key={product.id + "-" + index}
                      onClick={(e) =>
                        changeBgImage(e, index, selectedVariation.image[index])
                      }
                    >
                      <div className="lazy-media">
                        <figure className="mb-0">
                          <div className="lazy-overlay"></div>
                          <LazyLoadImage
                            alt="Thumbnail"
                            src={
                              Global.PHOTO_URL + selectedVariation.image[index]
                            }
                            width="100%"
                            height="auto"
                            className="d-block"
                          />
                        </figure>
                      </div>
                    </button>
                  ))}
                </div>
              </>
            </div>
            <div className="col-lg-6 quickview-desc pl-0 pl-lg-4 pr-0">
              <div className="entry-summary row">
                <div className="col-md-12">
                  <div className="entry-summary1 mt-2 mt-md-0"></div>
                </div>
                <div className="col-md-12">
                  <div className="entry-summary2"></div>
                </div>
              </div>
              <DetailOne
                product={product}
                variation={selectedVariation}
                changeColor={changeColor}
                changeSize={changeSize}
                chartMeasurements={[]}
                chartSizes={[]}
                chartValues={[]}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        title="Close (Esc)"
        type="button"
        className="mfp-close"
        onClick={closeModal}
      >
        <span>×</span>
      </button>
    </Modal>
  );
}

export default QuickViewModal;
