import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import ALink from "../../../features/alink";

import { cartQtyTotal, cartPriceTotal } from "../../../../utils";
import { Fragment } from "react";
import { Global } from "../../../../Global";
import {
  addAllToCart,
  RemoveFromCart,
  GetCartItems,
} from "../../../../store/slices/cart-slice";

function CartMenu() {
  const dispatch = useDispatch();

  const cartlist = useSelector((state) => state.cart.cartItems);
  const { customerId, guestId } = useSelector((state) => state.credential);
  const { currencyId, currencySymbol } = useSelector((state) => state.currency);

  const RemoveProductFromCart = (productVariationId) => {
    let promise;
    let result;

    const offset = new Date().getTimezoneOffset();

    result = dispatch(RemoveFromCart(customerId, guestId, productVariationId));
    promise = Promise.resolve(result);
    promise.then((value) => {
      if (value) {
        result = dispatch(
          GetCartItems(customerId, guestId, offset, currencyId)
        );
        promise = Promise.resolve(result);
        promise.then((value) => {
          dispatch(addAllToCart(value.Message));
        });
      }
    });
  };

  return (
    <div className="dropdown cart-dropdown">
      <ALink
        href="/cart"
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-display="static"
      >
        <i className="icon-shopping-cart"></i>
        <span className="cart-count">{cartQtyTotal(cartlist)}</span>
        <span className="cart-txt">
          {cartPriceTotal(cartlist).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
            " " +
            currencySymbol}
        </span>
      </ALink>

      <div
        className={`dropdown-menu dropdown-menu-right ${
          cartlist.length === 0 ? "text-center" : ""
        }`}
      >
        {cartlist.length === 0 ? (
          <p>No products in the cart.</p>
        ) : (
          <Fragment>
            <div className="dropdown-cart-products">
              {cartlist.map((item, index) => (
                <div className="product justify-content-between" key={index}>
                  <div className="product-cart-details">
                    <h4 className="product-title">
                      <ALink href={`/product/${item.id}`}>{item.name}</ALink>
                    </h4>
                    <span className="cart-product-info">
                      Size: {item.size}
                    </span>
                    <br/>
                    <span className="cart-product-info">
                      Color: {item.color}
                    </span>
                    <br/>
                    <br/>
                    <span className="cart-product-info">
                      <span className="cart-product-qty">{item.quantity} </span>
                      x
                      {item.saleprice ? (
                        <Fragment>
                          <strike>
                            {item.price.toFixed(2) + " " + currencySymbol}
                          </strike>
                          <span className="cart-product-price">
                            {item.saleprice.toFixed(2) + " " + currencySymbol}
                          </span>
                        </Fragment>
                      ) : (
                        <span>
                          {item.price.toFixed(2) + " " + currencySymbol}
                        </span>
                      )}
                    </span>
                  </div>

                  <figure className="product-image-container ml-2">
                    <ALink
                      href={`/product/${item.id}`}
                      className="product-image"
                    >
                      <img
                        src={Global.PHOTO_URL + item.image[0]}
                        alt="product"
                      />
                    </ALink>
                  </figure>
                  <button
                    className="btn-remove"
                    title="Remove Product"
                    onClick={() => {
                      RemoveProductFromCart(item.productVariationId);
                      ReactGA.event({
                        category: "remove variation from cart",
                        action: "Click",
                        label:
                          "remove variation from cart with id" +
                          item.productVariationId +
                          " of name " +
                          item.name +
                          " with quantity  " +
                          item.quantity,
                      });
                    }}
                  >
                    <i className="icon-close"></i>
                  </button>
                </div>
              ))}
            </div>
            <div className="dropdown-cart-total">
              <span>Total</span>

              <span className="cart-total-price">
                {cartPriceTotal(cartlist).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) +
                  " " +
                  currencySymbol}
              </span>
            </div>

            <div className="dropdown-cart-action">
              <ALink href="/cart" className="btn btn-primary">
                View Cart
              </ALink>
              <ALink href="/checkout" className="btn btn-outline-primary-2">
                <span>Checkout</span>
                <i className="icon-long-arrow-right"></i>
              </ALink>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default CartMenu;
