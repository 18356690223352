import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ALink from "../alink";

import { isInWishlist } from "../../../utils";
import QuickViewModal from "../modals/quickview-modal";
import { Global } from "../../../Global";
import {
  AddToWishlist,
  addToWishlist,
} from "../../../store/slices/wishlist-slice";
import {
  addAllToCart,
  AddToCart,
  GetCartItems,
} from "../../../store/slices/cart-slice";
import ReactGA from "react-ga";

function ProductTwelve(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product } = props;
  const [modalShow, setModalShow] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState({
    color: "",
    hex: "",
    image: [],
    discount: 0,
    size: "",
    price: 0,
    productVariationId: 0,
    saleprice: 0,
    stock: 0,
  });

  const wishlist = useSelector((state) => state.wishlist.wishlistItems);
  const customerId = useSelector((state) => state.credential.customerId);
  const guestId = useSelector((state) => state.credential.guestId);
  const { currencyId, currencySymbol } = useSelector((state) => state.currency);

  function onCartClick(e) {
    e.preventDefault();
    let promise;
    let result;
    ReactGA.event({
      category: "Add to cart",
      action: "Click",
      label:
        "Product Name:" +
        product.name +
        ", Variation Id:" +
        selectedVariation.productVariationId,
    });

    const offset = new Date().getTimezoneOffset();

    result = dispatch(
      AddToCart(customerId, guestId, selectedVariation.productVariationId, 1)
    );
    promise = Promise.resolve(result);
    promise.then((value) => {
      if (value) {
        result = dispatch(
          GetCartItems(customerId, guestId, offset, currencyId)
        );
        promise = Promise.resolve(result);
        promise.then((value) => {
          dispatch(addAllToCart(value.Message));
        });
      }
    });
  }

  useEffect(() => {
    product.variation.forEach((color) => {
      if (color.size.some((size) => size.isDefaultVariation)) {
        color.size.forEach((size) => {
          if (size.isDefaultVariation) {
            setSelectedVariation({
              color: color.color,
              image: color.image,
              hex: color.hex,
              discount: size.discount,
              size: size.name,
              price: size.price,
              productVariationId: size.productVariationId,
              saleprice: size.saleprice,
              stock: size.stock,
            });
          }
        });
      }
    });
  }, [product]);

  const AddProductToWishlist = (productId) => {
    let promise;
    let result;

    result = dispatch(AddToWishlist(customerId, guestId, productId));
    promise = Promise.resolve(result);
    promise.then((value) => {
      if (value) {
        result = dispatch(addToWishlist(product));
      }
    });
  };

  function onQuickView(e) {
    e.preventDefault();
    setModalShow(true);
    ReactGA.event({
      category: "Quick View",
      action: "Click",
      label: "Product Name:" + product.name + ", product Id:" + product.id,
    });
  }

  return (
    <div className="product product-7 text-center">
      <figure className="product-media">
        {product.new ? (
          <span
            className="product-label label-new"
            onClick={() => navigate(`/product/${product.id}`)}
          >
            New
          </span>
        ) : (
          ""
        )}

        {selectedVariation.saleprice !== 0 ? (
          <span
            className="product-label label-sale"
            onClick={() => navigate(`/product/${product.id}`)}
          >
            Sale
          </span>
        ) : (
          ""
        )}

        {/* {!selectedVariation.stock || selectedVariation.stock === 0 ? (
          <span className="product-label label-out ">Out of Stock</span>
        ) : (
          ""
        )} */}

        <ALink href={`/product/${product.id}`}>
          <LazyLoadImage
            alt="product"
            src={
              selectedVariation.image.length !== 0
                ? Global.PHOTO_URL + selectedVariation.image[0]
                : ""
            }
            threshold={500}
            effect="black and white"
            wrapperClassName="product-image"
          />
          {selectedVariation.image.length >= 2 ? (
            <LazyLoadImage
              alt="product"
              src={Global.PHOTO_URL + selectedVariation.image[1]}
              threshold={500}
              effect="black and white"
              wrapperClassName="product-image-hover"
            />
          ) : (
            ""
          )}
        </ALink>

        <div className="product-action-vertical">
          {isInWishlist(wishlist, product) ? (
            <ALink
              href="/wishlist"
              className="btn-product-icon btn-wishlist btn-expandable added-to-wishlist"
            >
              <span>go to wishlist</span>
            </ALink>
          ) : (
            <button
              className="btn-product-icon btn-wishlist btn-expandable"
              onClick={() => {
                AddProductToWishlist(product.id);
                ReactGA.event({
                  category: customerId === 0 ? "guest with id "+ guestId +"add Product to wishlist" :"Customer with id "+ customerId +"add Product to wishlist" ,
                  action: "Click",
                  label:
                    "Product Name:" +
                    product.name +
                    ", Product Id:" +
                    product.id,
                });}}
            >
              <span>add to wishlist</span>
            </button>
          )}
          <a
            href="/"
            className="btn-product-icon btn-quickview"
            title="Quick View"
            onClick={onQuickView}
          >
            <span>quick view</span>
          </a>
        </div>

        <div className="product-action product-action-transparent">
          {selectedVariation.stock > 0 ? (
            <button className="btn-product btn-cart" onClick={onCartClick}>
              <span className="border-bottom-0">add to cart</span>
            </button>
          ) : (
            <ALink
              href={`/product/${product.id}`}
              className="btn-product btn-cart btn-select"
            >
              <span>Out of Stock</span>
            </ALink>
          )}
        </div>
      </figure>

      <div className="product-body">
        {/* <div className="product-cat">
          {product.category.map((item, index) => (
            <React.Fragment key={index}>
              <ALink>{item}</ALink>
              {index < product.category.length - 1 ? ", " : ""}
            </React.Fragment>
          ))}
        </div> */}

        <h3 className="product-title">
          <ALink href={`/product/${product.id}`}>{product.name}</ALink>
        </h3>

        {selectedVariation.saleprice === 0 ? (
          <div className="product-price">
            <span className="out-price">
              {selectedVariation.price.toFixed(2) + " " + currencySymbol}
            </span>
          </div>
        ) : (
          <div className="product-price">
            <span className="new-price">
              Now{" "}
              {selectedVariation.saleprice.toFixed(2) + " " + currencySymbol}
            </span>
            <span className="old-price">
              Was {selectedVariation.price.toFixed(2) + " " + currencySymbol}
            </span>
          </div>
        )}
      </div>
      <QuickViewModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        variation={selectedVariation}
      />
    </div>
  );
}

export default ProductTwelve;
