import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GetMenu, setMenu } from "./store/slices/menu-slice";
import {
  CheckTokenExpiry,
  GenerateGuestId,
  setCredential,
} from "./store/slices/credential-slice";
import { GetAddresses, setAddress } from "./store/slices/address-slice";
import {
  addAllToCart,
  deleteAllFromCart,
  GetCartItems,
} from "./store/slices/cart-slice";
import {
  addAllToWishlist,
  deleteAllFromWishlist,
  GetWishlistItems,
} from "./store/slices/wishlist-slice";

import Home from "./pages/Home";

import { getCountries, setCountries } from "./store/slices/address-slice";
import { getContents, setContents } from "./store/slices/widget-slice";

const Contact = lazy(() => import("./pages/Contact"));
const ShopGrid = lazy(() => import("./pages/ShopGrid"));
const Product = lazy(() => import("./pages/Product"));
const Wishlist = lazy(() => import("./pages/Wishlist"));
const Cart = lazy(() => import("./pages/Cart"));
const Checkout = lazy(() => import("./pages/Checkout"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Support = lazy(() => import("./pages/Support"));
const EmailConfirmation = lazy(() => import("./pages/EmailConfirmation"));
const Order = lazy(() => import("./pages/Order"));
const CancelOrder = lazy(() => import("./pages/CancelOrder"));

function App() {
  const dispatch = useDispatch();

  const isLogged = useSelector((state) => state.credential.isLogged);
  const guestId = useSelector((state) => state.credential.guestId);
  const customerId = useSelector((state) => state.credential.customerId);
  const customerToken = useSelector((state) => state.credential.customerToken);
  const currencyId = useSelector((state) => state.currency.currencyId);

  useEffect(() => {
    const offset = new Date().getTimezoneOffset();
    let result;
    let promise;

    if (!isLogged && (guestId === "" || guestId === undefined)) {
      result = dispatch(GenerateGuestId());
      dispatch(
        setCredential({
          isLogged: false,
          guestId: result,
          customerId: 0,
          customerToken: "",
          firstName: "",
          lastName: "",
          email: "",
          purchasedProducts: [],
        })
      );

      dispatch(deleteAllFromCart());
      dispatch(deleteAllFromWishlist());
    } else if (!isLogged && guestId !== "" && guestId !== undefined) {
      dispatch(
        setCredential({
          isLogged: false,
          guestId: guestId,
          customerId: 0,
          customerToken: "",
          firstName: "",
          lastName: "",
          email: "",
          purchasedProducts: "",
        })
      );
    } else if (isLogged && customerId !== 0) {
      result = dispatch(CheckTokenExpiry(customerToken, customerId));
      promise = Promise.resolve(result);

      promise.then((value) => {
        if (value.Code === 0) {
          dispatch(
            setCredential({
              isLogged: true,
              guestId: "",
              customerId: value.Message.CustomerId,
              customerToken: value.Message.CustomerToken,
              firstName: value.Message.FirstName,
              lastName: value.Message.LastName,
              email: value.Message.Email,
              purchasedProducts: value.Message.purchasedProducts,
            })
          );
          result = dispatch(GetAddresses(customerId));
          promise = Promise.resolve(result);
          promise.then((value) => {
            dispatch(setAddress(value));
          });
        } else {
          result = dispatch(GenerateGuestId());
          dispatch(
            setCredential({
              isLogged: false,
              guestId: result,
              customerId: 0,
              customerToken: "",
              firstName: "",
              lastName: "",
              email: "",
              purchasedProducts: [],
            })
          );

          dispatch(deleteAllFromCart());
          dispatch(deleteAllFromWishlist());
        }
      });
    }

    //cart
    result = dispatch(GetCartItems(customerId, guestId, offset, currencyId));
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(addAllToCart(value.Message));
    });

    //wishlist
    result = dispatch(
      GetWishlistItems(customerId, guestId, offset, currencyId)
    );
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(addAllToWishlist(value.Message));
    });

    //menu
    result = dispatch(GetMenu());
    promise = Promise.resolve(result);
    promise.then((value) => {
      if (value) {
        dispatch(setMenu(value));
      }
    });

    //countries
    result = dispatch(getCountries());
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(setCountries(value));
    });

    //countries
    result = dispatch(getContents());
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(setContents(value));
    });
  }, [dispatch, isLogged, guestId, customerId, customerToken, currencyId]);

  return (
    <Router>
      <Suspense>
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />

          <Route
            path={
              process.env.PUBLIC_URL +
              "/category/:category/:id/:type/:pagenumber"
            }
            element={<ShopGrid />}
          />

          <Route
            path={
              process.env.PUBLIC_URL + "/brand/:brand/:id/:type/:pagenumber"
            }
            element={<ShopGrid />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/search/:search/:type/:pagenumber"}
            element={<ShopGrid />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/product/:id"}
            element={<Product />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/wishlist"}
            element={<Wishlist />}
          />

          <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />

          <Route
            path={process.env.PUBLIC_URL + "/checkout"}
            element={<Checkout />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/contact"}
            element={<Contact />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/dashboard"}
            element={<Dashboard />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/forgot-password"}
            element={<ForgetPassword />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/forget-password/:email/:token"}
            element={<ResetPassword />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/email-confirm/:email/:code"}
            element={<EmailConfirmation />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/order-success/:orderNumber"}
            element={<Order />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/page/:page/:id"}
            element={<Support />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/Order/Cancel/C/Cancelled"}
            element={<CancelOrder />}
          />

          <Route path={process.env.PUBLIC_URL + "*"} element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
