import { Global } from "../../Global";
import cogoToast from "cogo-toast";

const { createSlice } = require("@reduxjs/toolkit");

const addressSlice = createSlice({
  name: "address",
  initialState: {
    countries: [],
    addressBook:[]
  },
  reducers: {
    setAddress(state, action) {
      state.addressBook = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
  },
});

export const getCountries = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "Country/GetAllPublishedCountries"
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
    }
  };
};

export const getCities = (countryId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "Country/GetCitiesByCountryCode/" + countryId
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
    }
  };
};
export const GetAddresses = (customerId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "AddressBook/GetAddressBooks/" + customerId
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
    }
  };
};

export const { setAddress, setCountries } = addressSlice.actions;
export default addressSlice.reducer;
