import cogoToast from "cogo-toast";
import { Global } from "../../Global";
const { createSlice } = require("@reduxjs/toolkit");

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlistItems: [],
  },
  reducers: {
    addToWishlist(state, action) {
      const isInWishlist = state.wishlistItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (isInWishlist === -1) {
        state.wishlistItems.push(action.payload);
      } else {
      }
    },
    addAllToWishlist(state, action) {
      state.wishlistItems = action.payload;
    },
    deleteFromWishlist(state, action) {
      state.wishlistItems = state.wishlistItems.filter(
        (item) => item.id !== action.payload
      );
    },
    deleteAllFromWishlist(state) {
      state.wishlistItems = [];
    },
  },
});

export const GetWishlistItems = (customerId, guestId, offset, currencyId) => {
  return async (dispatch) => {
    let group = 1;
    if (customerId !== 0) group = 2;
    const response = await fetch(
      Global.API_URL +
        "Wishlist/GetWishlistItems/Offset/" +
        offset +
        "/Country/125/Group/" +
        group +
        "/Currency/" +
        currencyId +
        "?CustomerId=" +
        customerId +
        "&GuestId=" +
        guestId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      const result = await response.json();
      return result;
    }
  };
};

export const AddToWishlist = (customerId, guestId, productId) => {
  return async (dispatch) => {
    try {
      let response;
      response = await fetch(Global.API_URL + "Wishlist/AddItemToWishlist", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.HostAPI,
        },
        body: JSON.stringify({
          customerId: customerId,
          productId: productId,
          guestId: guestId,
        }),
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      return true;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
      return false;
    }
  };
};

export const RemoveFromWishlist = (customerId, guestId, productId) => {
  return async (dispatch) => {
    try {
      let response;
      response = await fetch(
        Global.API_URL +
          "Wishlist/RemoveItemFromWishlist/" +
          productId +
          "?CustomerId=" +
          customerId +
          "&GuestId=" +
          guestId,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
          body: JSON.stringify({
            customerId: customerId,
            productId: productId,
            guestId: guestId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      return true;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
      return false;
    }
  };
};

export const {
  addToWishlist,
  addAllToWishlist,
  deleteFromWishlist,
  deleteAllFromWishlist,
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
