import React from "react";
import { useSelector } from "react-redux";

import ALink from "../../features/alink";

function Footer() {
  const { contents } = useSelector((state) => state.widget);

  return (
    <footer className="footer">
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="widget widget-about">
                <h4 className="widget-title">About SIS</h4>
                <p>
                  Launching a kids’ clothing line is a great way to let your
                  creativity shine. Kids’ clothes come in a variety of patterns
                  and designs.
                </p>

                <div className="social-icons">
                  <a
                    href="https://www.facebook.com/SIS.BrandOfficial/"
                    className="social-icon"
                    title="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/smallinsize.ae/"
                    className="social-icon"
                    title="Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-instagram"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Useful Links</h4>

                <ul className="widget-list">
                  <li>
                    <ALink href="/">Home</ALink>
                  </li>
                  <li>
                    <ALink href="/contact">Contact us</ALink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Customer Service</h4>

                <ul className="widget-list">
                  {contents.map((content, index) => {
                    return (
                      <li key={index}>
                        <ALink
                          href={
                            "/page/" +
                            content.Title.trim().replace(/ /g, "-") +
                            "/" +
                            content.ContentId
                          }
                        >
                          {content.Title}
                        </ALink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Get in touch</h4>

                <ul className="widget-list">
                  <li>
                    <span className="margin-right-10">
                      <i className="icon-home"></i>
                    </span>
                    <ALink href="/">
                      Mazaya Business Avenue Jumeirah Lakes Tower Office AA1
                    </ALink>
                  </li>
                  <li>
                    <span className="margin-right-10">
                      <i className="icon-envelope"></i>
                    </span>
                    <ALink href="mailto:customercare@smallinsize.com">
                      customercare@smallinsize.com
                    </ALink>
                  </li>
                  <li>
                    <span className="margin-right-10">
                      <i className="icon-phone"></i>
                    </span>
                    <ALink href="tel:+971505013003">+971 50 5013003</ALink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <figure className="footer-payments">
            <img
              src="/assets/images/visa.svg"
              alt="Payment methods"
              width="30"
              height="30"
            />
            <img
              src="/assets/images/mastercard.svg"
              alt="Payment methods"
              width="30"
              height="30"
            />
          </figure>
          <img
            src="/assets/images/SISlogo.png"
            alt="Footer Logo"
            width="82"
            height="25"
          />

          <p className="footer-copyright">
            Copyright © {new Date().getFullYear()} SIS Store. All Rights
            Reserved. Powered by Vision & More.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
