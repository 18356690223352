import React from "react";
import ALink from "../components/features/alink";
import LoginModal from "../components/features/modals/LoginModal";
import WishlistMenu from "../components/partials/header/partials/wishlist-menu";
import CartMenu from "../components/partials/header/partials/cart-menu";
import StickyHeader from "../components/features/sticky-header";
import MainMenu from "../components/partials/header/partials/main-menu";
import HeaderSearch from "../components/partials/header/partials/header-search";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedParent } from "../store/slices/menu-slice";
import { setCurrency } from "../store/slices/currency-slice";
import ReactGA from "react-ga";

const Header = () => {
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.credential);
  const { currencySymbol } = useSelector((state) => state.currency);

  const ChangeCurrency = (newCurrencyId, newCurrencySymbol) => {
    dispatch(
      setCurrency({
        currencyId: newCurrencyId,
        currencySymbol: newCurrencySymbol,
      })
    );
    ReactGA.event({
      category: "Change Currency",
      action: "Click",
      label: newCurrencySymbol,
    });
  };

  function openMobileMenu() {
    document.querySelector("body").classList.add("mmenu-active");
  }

  return (
    <header className="header header-6">
      <div className="header-top">
        <div className="container">
          <div className="header-left">
            <ul className="top-menu top-link-menu d-none d-md-block">
              <li>
                <ALink href="/">Links</ALink>
                <ul>
                  <a href="tel:+971 50 5013003" className="margin-right-20">
                    <i className="icon-phone"></i>Call: +971 50 5013003
                  </a>
                  <a href="mailto:info@smallinsize.com">
                    <i className="icon-envelope"></i>
                    <p className="font-weight-semibold">info@smallinsize.com</p>
                  </a>
                </ul>
              </li>
            </ul>
          </div>

          <div className="header-right">
            <div className="social-icons social-icons-color">
              <a
                href="https://www.facebook.com/SIS.BrandOfficial/"
                className="social-icon social-facebook"
                title="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/smallinsize.ae/"
                className="social-icon social-instagram"
                title="Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-instagram"></i>
              </a>
            </div>
            <ul className="top-menu top-link-menu">
              <li>
                <ALink href="/">Links</ALink>
                <ul>
                  {isLogged ? (
                    <ALink href="/dashboard">My Account</ALink>
                  ) : (
                    <LoginModal />
                  )}
                </ul>
              </li>
            </ul>

            <div className="header-dropdown">
              <ALink href="/">{currencySymbol}</ALink>
              <div className="header-menu">
                <ul>
                  <li>
                    <button onClick={() => ChangeCurrency(1, "AED")}>
                      AED
                    </button>
                  </li>
                  <li>
                    <button onClick={() => ChangeCurrency(2, "USD")}>
                      USD
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-middle">
        <div className="container">
          <div className="header-left">
            <HeaderSearch />
          </div>

          <div className="header-center">
            <ALink href="/" className="logo">
              <img
                src="/assets/images/SISlogo.png"
                alt="SIS Logo"
                onClick={() => dispatch(setSelectedParent(0))}
              />
            </ALink>
          </div>

          <div className="header-right">
            <WishlistMenu />
            <CartMenu />
          </div>
        </div>
      </div>

      <StickyHeader>
        <div className="header-bottom sticky-header">
          <div className="container ">
            <div className="header-left">
              <MainMenu />

              <button className="mobile-menu-toggler" onClick={openMobileMenu}>
                <span className="sr-only">Toggle mobile menu</span>
                <i className="icon-bars"></i>
              </button>
            </div>

            <div className="header-center"></div>
            {/* 
            <div className="header-right overflow-hidden">
              <i className="icon-envelope"></i>
              <p className="font-weight-semibold">info@smallinsize.com</p>
            </div> */}
          </div>
        </div>
      </StickyHeader>
    </header>
  );
};

export default Header;
