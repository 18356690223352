import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Modal from "react-modal";
import { Global } from "../../../Global";
import cogoToast from "cogo-toast";
const customStyles = {
  overlay: {
    backgroundColor: "rgba(51,51,51,0.6)",
    zIndex: "9001",
  },
};

Modal.setAppElement("body");

function NewsletterModal() {
  const [open, setOpen] = useState(false);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Slider/GetSlidesBySliderId/3",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        const result = await response.json();
        setSliderData(result[0].slides);
        if (result[0].slides.length > 0) {
          if (
            sessionStorage.getItem("hideNewsletter") === null ||
            sessionStorage.getItem("hideNewsletter") === "false"
          ) {
            sessionStorage.setItem(`hideNewsletter`, "false");
            setTimeout(() => {
              setOpen(true);
            }, 2500);
          }
        }
        // dispatch(setSlides(result[0].slides));
      } catch (error) {
        cogoToast.error(error.Message || "Something went wrong", {
          position: "bottom-left",
        });
      }
    };
    fetchSlider();
  }, []);

  function closeModal(e) {
    document
      .getElementById("newsletter-popup-form")
      .classList.remove("ReactModal__Content--after-open");

    if (document.querySelector(".ReactModal__Overlay")) {
      document.querySelector(".ReactModal__Overlay").style.opacity = "0";
    }
    sessionStorage.setItem(`hideNewsletter`, "true");

    setOpen(false);
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles}
      shouldReturnFocusAfterClose={false}
      contentLabel="Newsletter Modal"
      className="container newsletter-popup-container h-auto"
      overlayClassName="d-flex align-items-center justify-content-center"
      id="newsletter-popup-form"
    >
      <div className="modal-content overflow-hidden">
        <div className="row justify-content-center position-relative">
          <div className="col-12">
            <div className="row no-gutters bg-white newsletter-popup-content">
              {/* <div className="col-xl-3-5col col-lg-7 banner-content-wrap">
                <div className="banner-content text-center">
                  <img
                    src="/assets/images/SISlogo.png"
                    alt="logo"
                    className="logo"
                    width="100"
                    height="100"
                  />
                  <h2 className="banner-title">
                    get{" "}
                    <span>
                      20<span style={{ fontWeight: "400" }}>%</span>
                    </span>{" "}
                    off
                  </h2>
                  <p>
                    on your first order using the coupon code:{" "}
                    <span>new20</span>
                  </p>
                </div>
              </div>

              <div className="col-xl-2-5col col-lg-5 d-none d-lg-block">
                <div className="lazy-overlay"></div>
                <LazyLoadImage
                  alt="newsletter"
                  src="/assets/images/popup/newsletter/img-1.jpg"
                  threshold={0}
                  width={396}
                  height={420}
                  effect="blur"
                  className="newsletter-img"
                />
              </div> */}
              <div className="col-12">
                <div className="lazy-overlay"></div>
                {sliderData.length > 0 && (
                  <LazyLoadImage
                    alt="newsletter"
                    src={Global.PHOTO_URL + sliderData[0].SlideImage}
                    threshold={0}
                    width={396}
                    height={420}
                    effect="blur"
                    className="newsletter-img"
                  />
                )}
              </div>
            </div>
          </div>
          <button
            title="Close (Esc)"
            type="button"
            className="mfp-close"
            onClick={closeModal}
          >
            <span>×</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default NewsletterModal;
