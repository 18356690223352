import React, { useEffect, useState, useRef, Fragment } from "react";

import ALink from "../../../features/alink";
import Qty from "../../../features/qty";
import ReactGA from "react-ga";
import { canAddToCart, isInWishlist } from "../../../../utils";
import { useSelector, useDispatch } from "react-redux";
import SizeChartModal from "../../../features/modals/sizechart-modal";

import {
  AddToWishlist,
  addToWishlist,
} from "../../../../store/slices/wishlist-slice";
import {
  addAllToCart,
  AddToCart,
  GetCartItems,
} from "../../../../store/slices/cart-slice";

function DetailOne(props) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const {
    product,
    variation,
    changeColor,
    changeSize,
    chartSizes,
    chartMeasurements,
    chartValues,
  } = props;

  const [quantityCount, setQuantityCount] = useState(1);
  const [selectedVariation, setSelectedVariation] = useState({
    color: "",
    hex: "",
    image: [],
    discount: 0,
    size: "",
    price: 0,
    productVariationId: 0,
    saleprice: 0,
    stock: 0,
  });

  const [show, setShow] = useState(false);

  const cartItems = useSelector((state) => state.cart.cartItems);
  const wishlist = useSelector((state) => state.wishlist.wishlistItems);
  const customerId = useSelector((state) => state.credential.customerId);
  const guestId = useSelector((state) => state.credential.guestId);
  const { currencyId, currencySymbol } = useSelector((state) => state.currency);

  const AddProductToWishlist = (productId) => {
    let promise;
    let result;

    result = dispatch(AddToWishlist(customerId, guestId, productId));
    promise = Promise.resolve(result);
    promise.then((value) => {
      if (value) {
        result = dispatch(addToWishlist(product));
      }
    });
  };

  const AddProductToCart = () => {
    let promise;
    let result;

    const offset = new Date().getTimezoneOffset();

    result = dispatch(
      AddToCart(
        customerId,
        guestId,
        selectedVariation.productVariationId,
        quantityCount
      )
    );
    promise = Promise.resolve(result);
    promise.then((value) => {
      if (value) {
        result = dispatch(
          GetCartItems(customerId, guestId, offset, currencyId)
        );
        promise = Promise.resolve(result);
        promise.then((value) => {
          dispatch(addAllToCart(value.Message));
          ReactGA.event({
            category: customerId === 0 ?"guest with id " + guestId +   "add variation to cart" :"Customer with id " + customerId +   "add variation to cart"  ,
            action: "Click",
            label:
              "add variation to cart with id" +
              selectedVariation.productVariationId +
              " of name " +
              product.name +
              " with quantity  " +
              quantityCount,
          });
        });
      }
    });
  };

  function onChangeQty(current) {
    setQuantityCount(current);
  }

  useEffect(() => {
    setSelectedVariation(variation);
  }, [variation]);

  if (!product) {
    return <div></div>;
  }

  return (
    <div className="product-details" ref={ref}>
      <h1 className="product-title">{product.name}</h1>

      {product.rating !== "NaN" && (
        <div className="ratings-container">
          <div className="ratings">
            <div
              className="ratings-val"
              style={{
                width: parseInt(product.rating) * 20 + "%",
              }}
            ></div>
            <span className="tooltip-text">{parseInt(product.ratings)}</span>
          </div>
        </div>
      )}

      {selectedVariation.saleprice === 0 ? (
        <div className="product-price">
          {selectedVariation.price.toFixed(2) + " " + currencySymbol}
        </div>
      ) : (
        <div className="product-price">
          <span className="new-price">
            {selectedVariation.saleprice.toFixed(2) + " " + currencySymbol}
          </span>
          <span className="old-price">
            {selectedVariation.price + " " + currencySymbol}
          </span>
        </div>
      )}

      <div className="product-content">
        <div
          dangerouslySetInnerHTML={{
            __html: product.shortDescription,
          }}
        ></div>
      </div>

      {product.variation.length > 0 ? (
        <Fragment>
          <div className="details-filter-row details-row-size">
            <label>Color:</label>

            <div className="product-nav product-nav-dots">
              {product.variation.map((single, index) => {
                return (
                  <button
                    className={`${
                      single.color === selectedVariation.color ? "active " : ""
                    }`}
                    style={{ backgroundColor: single.hex }}
                    key={index}
                    onClick={() => {
                      changeColor(single.color);
                      ReactGA.event({
                        category: "Change Color",
                        action: "Click",
                        label: "Change Color to " + single.color,
                      });
                    }}
                  ></button>
                );
              })}
            </div>
          </div>

          {selectedVariation.size !== "" && (
            <div className="details-filter-row details-row-size">
              <label htmlFor="size">Size:</label>
              <div className="select-custom">
                <select
                  name="size"
                  className="form-control"
                  value={selectedVariation.size}
                  onChange={(e) => {
                    changeSize(e.target.value);
                    ReactGA.event({
                      category: "Change Size",
                      action: "Click",
                      label: "Change size to " + e.target.value,
                    });
                  }}
                >
                  {product.variation &&
                    product.variation.map((single) => {
                      return single.color === selectedVariation.color
                        ? single.size.map((singleSize, index) => {
                            return (
                              <option value={singleSize.name} key={index}>
                                {singleSize.name}
                              </option>
                            );
                          })
                        : "";
                    })}
                </select>
              </div>

              {chartValues.length !== 0 && (
                <button
                  className="size-guide mr-4"
                  onClick={() => {
                    setShow(true);
                    ReactGA.event({
                      category: "open Size Chart",
                      action: "Click",
                      label: "Open Size Chart of product " + product.name,
                    });
                  }}
                >
                  <i className="icon-th-list"></i>size guide
                </button>
              )}
            </div>
          )}
        </Fragment>
      ) : (
        ""
      )}

      <div className="details-filter-row details-row-size">
        <label htmlFor="qty">Qty:</label>
        <Qty
          changeQty={onChangeQty}
          max={selectedVariation.stock}
          value={quantityCount}
        ></Qty>
      </div>

      <div className="product-details-action">
        {!canAddToCart(cartItems, selectedVariation, quantityCount) ? (
          <button
            className={`btn-product btn-cart ${
              !canAddToCart(cartItems, selectedVariation, quantityCount)
                ? "btn-disabled"
                : ""
            }`}
            disabled={
              !canAddToCart(cartItems, selectedVariation, quantityCount)
            }
            onClick={AddProductToCart}
          >
            <span>Out of Stock</span>
          </button>
        ) : (
          <button
            className={`btn-product btn-cart ${
              !canAddToCart(cartItems, selectedVariation, quantityCount)
                ? "btn-disabled"
                : ""
            }`}
            disabled={
              !canAddToCart(cartItems, selectedVariation, quantityCount)
            }
            onClick={AddProductToCart}
          >
            <span>add to cart</span>
          </button>
        )}

        <div className="details-action-wrapper">
          {isInWishlist(wishlist, product) ? (
            <ALink
              href="/wishlist"
              className="btn-product btn-wishlist added-to-wishlist"
            >
              <span>Go to Wishlist</span>
            </ALink>
          ) : (
            <button
              className="btn-product btn-wishlist"
              onClick={() => {
                AddProductToWishlist(product.id);
                ReactGA.event({
                  category:
                    customerId === 0
                      ? "guest with id " + guestId + "add Product to wishlist"
                      : "Customer with id " +
                        customerId +
                        "add Product to wishlist",
                  action: "Click",
                  label:
                    "Product Name:" +
                    product.name +
                    ", Product Id:" +
                    product.id,
                });
              }}
            >
              <span>Add to Wishlist</span>
            </button>
          )}
        </div>
      </div>

      <div className="product-details-footer">
        <div className="product-cat-modal w-100 text-truncate">
          <span>Category:</span>
          {product.category.map((item, index) => (
            <React.Fragment key={index}>
              <ALink>{item}</ALink>
              {index < product.category.length - 1 ? ", " : ""}
            </React.Fragment>
          ))}
        </div>

        <div className="social-icons social-icons-sm">
          <a
            href="https://www.facebook.com/SIS.BrandOfficial/"
            className="social-icon"
            title="Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-facebook-f"></i>
          </a>
          <a
            href="https://www.instagram.com/smallinsize.ae/"
            className="social-icon"
            title="Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-instagram"></i>
          </a>
        </div>
      </div>
      <div className="sticky-bar d-none">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <figure className="product-media">
                <ALink href={`/product/${product.id}`}>
                  <img src={selectedVariation.image[0]} alt="product" />
                </ALink>
              </figure>
              <h3 className="product-title">
                <ALink href={`/product/${product.id}`}>{product.name}</ALink>
              </h3>
            </div>
            <div className="col-6 justify-content-end">
              {selectedVariation.saleprice === 0 ? (
                <div className="product-price">
                  {selectedVariation.price.toFixed(2) + " " + currencySymbol}
                </div>
              ) : (
                <div className="product-price">
                  <span className="new-price">
                    {selectedVariation.saleprice.toFixed(2) +
                      " " +
                      currencySymbol}
                  </span>
                  <span className="old-price">
                    {selectedVariation.price.toFixed(2) + " " + currencySymbol}
                  </span>
                </div>
              )}

              <div className="product-details-action">
                <button
                  className={`btn-product btn-cart ${
                    !canAddToCart(cartItems, selectedVariation, quantityCount)
                      ? "btn-disabled"
                      : ""
                  }`}
                  disabled={
                    !canAddToCart(cartItems, selectedVariation, quantityCount)
                  }
                  onClick={AddProductToCart}
                >
                  <span>add to cart</span>
                </button>
                {isInWishlist(wishlist, product) ? (
                  <ALink
                    href="/wishlist"
                    className="btn-product btn-wishlist added-to-wishlist"
                  >
                    <span>Go to Wishlist</span>
                  </ALink>
                ) : (
                  <button
                    className="btn-product btn-wishlist"
                    onClick={() => {
                      AddProductToWishlist(product.id);
                      ReactGA.event({
                        category:
                          customerId === 0
                            ? "guest with id " +
                              guestId +
                              "add Product to wishlist"
                            : "Customer with id " +
                              customerId +
                              "add Product to wishlist",
                        action: "Click",
                        label:
                          "Product Name:" +
                          product.name +
                          ", Product Id:" +
                          product.id,
                      });
                    }}
                  >
                    <span>Add to Wishlist</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SizeChartModal
        show={show}
        onHide={() => setShow(false)}
        chartSizes={chartSizes}
        chartMeasurements={chartMeasurements}
        chartValues={chartValues}
      />
    </div>
  );
}

export default DetailOne;
