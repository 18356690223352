import React, { Fragment } from "react";
import Header from "../wrappers/Header";
import { isSafariBrowser, isEdgeBrowser } from "../utils";
import MobileMenu from "../components/features/mobile-menu";
import Footer from "../components/partials/footer/Footer";
import ReactWhatsapp from "react-whatsapp";
import NewsletterModal from "../components/features/modals/newsletter-modal";

const Layout = ({ children }) => {
  function hideMobileMenu() {
    document.querySelector("body").classList.remove("mmenu-active");
  }

  function toScrollTop() {
    if (isSafariBrowser() || isEdgeBrowser()) {
      let pos = window.pageYOffset;
      let timerId = setInterval(() => {
        if (pos <= 0) clearInterval(timerId);
        window.scrollBy(0, -120);
        pos -= 120;
      }, 1);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <Fragment>
      <div className="page-wrapper">
        <Header />
        {children}

        <div className="footer-whatsapp">
          <ReactWhatsapp
            number="971505013003"
            message=""
            className="whatsapp-icon"
          >
            <i className="icon-whatsapp"></i>
          </ReactWhatsapp>
        </div>
        <Footer />
      </div>

      <div className="mobile-menu-overlay" onClick={hideMobileMenu}></div>
      <button id="scroll-top" title="Back to top" onClick={toScrollTop}>
        <i className="icon-arrow-up"></i>
      </button>
      <MobileMenu />
      <NewsletterModal/>
    </Fragment>
  );
};

export default Layout;
