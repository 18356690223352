import React, { useEffect, useState } from "react";
import { Global } from "../../../Global";
import { useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import ReactGA from "react-ga"

import ALink from "../../features/alink";
import ProductTwelve from "../../features/products/product-twelve";

function NewCollection() {
  const [products, setProducts] = useState([]);

  const [widgetName, setWidgetName] = useState("");

  const isLogged = useSelector((state) => state.credential.isLogged);
  const { currencyId } = useSelector((state) => state.currency);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname+window.location.search)
    const fetchProducts = async () => {
      const offset = new Date().getTimezoneOffset();
      let groupId = 2;
      if (!isLogged) {
        groupId = 1;
      }

      try {
        const response = await fetch(
          Global.API_URL +
            "Widgets/GetProductsByWidgetId/10/Offset/" +
            offset +
            "/Country/125/GroupId/" +
            groupId +
            "/Currency/" +
            currencyId
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const result = await response.json();
        setWidgetName(result.WidgetName);
        setProducts(result.Products);
      } catch (error) {
        cogoToast.error(error.Message || "Something went wrong", {
          position: "bottom-left",
        });
      }
    };
    fetchProducts();
  }, [isLogged, currencyId]);

  return (
    <div className="container">
      <h2 className="title text-center margin-bottom-4">{widgetName}</h2>

      <div className="products">
        <div className="row justify-content-center">
          {products.slice(0, 10).map((product, index) => {
            return (
              <div className="col-6 col-md-4 col-lg-3" key={index}>
                <ProductTwelve product={product} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="more-container text-center mt-2">
        <ALink href="/shop/sidebar/list" className="btn btn-more">
          <span>show more</span>
        </ALink>
      </div>
    </div>
  );
}

export default NewCollection;
