export const Global = {
  // API_URL: "https://gantblancapi.vision-more.com/api/",
  // PHOTO_URL: "https://gantblancapi.vision-more.com/Uploads/",
  API_URL: "https://smallinsizeapi.vision-more.com/api/",
  PHOTO_URL: "https://smallinsizeapi.vision-more.com/Uploads/",
  Rate: 3.67,
  API_SHIP: "https://api.postshipping.com/api2/",
  TRACKING_ID: "G-YZ2BCZG1KM",
  TOKEN: "58FF597E63B5FF8A78D84812F26B534D",
  Payment_Authorization: "SHJNWLZDLT-J2DHW2BBWL-RNJGMKLTT2",
};
